import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconOog: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '4.1 12 55.8 38', width: '55.8', height: '38' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<path
				d={`M59.1333,28.3261C55.8632,23.0921,47.1462,12,32,12S8.1368,23.0921,4.8667,28.3261c-1.0223,1.6362-1.0223,3.7115,0,5.3477
C8.1368,38.9079,16.8538,50,32,50s23.8632-11.0921,27.1333-16.3262C60.1556,32.0377,60.1556,29.9623,59.1333,28.3261z M32,20.9
c3.369,0,6.1,2.7311,6.1,6.1s-2.731,6.1-6.1,6.1s-6.1-2.731-6.1-6.1S28.631,20.9,32,20.9z M32,45C16.6199,45,9.7844,31,9.7844,31
s3.1024-6.3386,9.8166-10.4856c-0.7806,1.4844-1.3101,3.1151-1.5068,4.8386C17.1279,33.8218,23.7256,41,32,41
c7.374,0,13.4165-5.7011,13.9602-12.936c0.3626-4.8261-4.0852-7.814-8.4634-10.129c-0.183-0.0968-0.1678-0.2968-0.1642-0.3411
C48.9703,20.2593,54.2156,31,54.2156,31S47.3801,45,32,45z`}
			/>
		</g>
	</svg>
);
