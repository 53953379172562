import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconHandel: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '0 11 63 48', width: '63', height: '48' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M20.9061,21.6205c1.5992,2.047,6.7167,0.5117,9.1475-0.4478c5.0121-1.9785,5.5031-3.2739,8.3732-0.9528l9.7992,7.9246
c6.6631-2.0927,7.8267-4.066,7.8267-4.066l-6.2789-12.825l-5.9795,1.9966c-2.3185,0.7743-3.8627-0.5413-4.8428-1.2023
c-2.098-1.4149-4.8498-1.3954-6.9276,0.0491c-2.0778,1.4446-5.2326,4.0862-10.0302,6.5811
C20.5713,19.4175,20.2272,20.7516,20.9061,21.6205z`}
				/>
				<path
					d={`M23.6013,38.5526l0.4163-0.344c1.2512-1.0339,1.2032-3.1576,0.1693-4.4089c-0.7027-0.8503-1.8843-1.3149-2.9639-1.2099
c0.9783-3.3209-2.9577-5.4837-4.9913-3.8034l-0.9875,0.816c0.0166-3.0699-3.3783-4.5342-5.4902-2.7891l-2.395,1.9791
c-1.9418,1.6046-1.7101,3.6646-0.6761,4.9158c1.1171,1.3519,2.833,1.4462,4.0913,0.8063
c-0.0509,0.8594,0.2105,1.7632,0.7572,2.4247c0.7026,0.8504,1.8843,1.3149,2.9638,1.2099
c-0.3065,1.0404-0.0731,2.2884,0.6296,3.1387c0.8256,0.9991,2.3109,1.4707,3.515,1.0997
c-0.1386,0.941,0.1171,1.9775,0.7257,2.7141c1.0339,1.2512,3.1642,1.6542,4.6854,0.3972l1.1651-0.9628
c1.6182-1.3372,1.6563-3.532,0.6224-4.7831C25.292,39.0919,24.4543,38.6649,23.6013,38.5526z`}
				/>
				<path
					d={`M37.2151,21.718c-1.3602-1.0999-1.3237-1.0261-3.5589-0.0034c-1.7825,0.8161-6.1432,2.8447-9.9286,2.8447
c-1.9711,0-3.4315-0.5897-4.3398-1.7525c-1.4111-1.8069-0.803-4.528,1.7166-5.8383c2.0516-1.0667,3.7948-2.1675,5.2833-3.1865
l-2.3589-1.64c-2.0778-1.4446-4.8296-1.4641-6.9276-0.0491c-0.9875,0.6659-2.5283,1.9752-4.8428,1.2023l-5.9795-1.9967L0,24.1236
c0,0,0.9412,1.5762,5.8918,3.4044c2.9808-2.7016,4.1333-3.3885,5.9224-3.3885c1.7408,0,3.4941,0.8693,4.4821,2.4297
c2.0795-0.7549,4.4775,0.0756,5.7822,1.6547c0.6203,0.7504,1.017,1.7033,1.1541,2.6994c3.1268,1.056,4.2759,4.3394,3.3364,6.8718
c2.1713,1.7276,2.4581,4.8201,0.9584,7.0353c1.6129,1.3624,3.28,0.9912,4.4726,0.0443v-6.3259
c0-5.2966,5.4653-9.3113,13.2056-10.3048C42.1789,25.765,47.22,29.8097,37.2151,21.718z`}
				/>
				<path
					d={`M49.0012,50C56.8511,50,63,46.6253,63,42.3214c0-0.6324,0-3.0202,0-3.6548C63,34.3673,56.8511,31,49.0012,31
C41.1492,31,35,34.3673,35,38.6666c0,0.6346,0,3.0225,0,3.6548c0,0.9493,0.3142,1.8492,0.865,2.6819
C35.312,45.8288,35,46.7224,35,47.6666v3.6548C35,55.6253,41.1492,59,49.0012,59C56.8511,59,63,55.6253,63,51.3214v-3.6548
c0-0.2049-0.0266-0.4056-0.0542-0.606c-0.7144,0.8958-4.5718,5.1376-13.6127,5.1376c-8.0173,0-10.7806-3.8577-11.0668-4.3807
c-0.1169-0.2137-0.2019-0.4362-0.0765-0.5667C40.738,48.9397,44.6016,50,49.0012,50z M37.7012,39
c0-2.8444,5.0601-5.15,11.302-5.15s11.302,2.3057,11.302,5.15c0,2.8443-5.0601,5.15-11.302,5.15S37.7012,41.8443,37.7012,39z`}
				/>
				<path
					d={`M46.8542,40.7261v0.6766h1.5551v-0.5565c0.3053,0.0191,0.6703,0.0191,1.016,0.0191h0.384v0.5374h1.5168v-0.6166
c2.7834-0.2999,4.1094-1.2643,4.1094-2.6701c0-0.5469-0.0571-0.7845-0.3076-1.3435H53.072
c0.2123,0.5182,0.2886,0.7773,0.2886,1.2546c0,0.6166-0.5938,1.1155-2.0344,1.334v-1.7634h-1.5168v1.8928
c-0.1907,0.0096-0.384,0.0193-0.6128,0.0193c-0.2697,0-0.52-0.0098-0.7872-0.0193v-1.8928h-1.5551v1.7538
c-1.4023-0.2089-2.1322-0.6575-2.1322-1.3748c0-0.4078,0.0763-0.6454,0.2479-1.1539h-2.168
c-0.174,0.4269-0.2312,0.7269-0.2312,1.1226C42.5708,39.3611,44.0112,40.3662,46.8542,40.7261z`}
				/>
			</g>
		</g>
	</svg>
);
