import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';

export const IconInvulformulier: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '12 7 40 50', width: '40', height: '50' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		role="img"
		aria-hidden={true}
		{...props}
	>
		<title>{translate('fillOutForm')}</title>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M50,7H28.8286c-0.5307,0-1.0395,0.2109-1.4145,0.5859L12.5859,22.4141C12.2109,22.7891,12,23.2979,12,23.8286V55
c0,1.0996,0.8999,2,2,2h36c1.1045,0,2-0.8955,2-2V9C52,7.8955,51.1045,7,50,7z M47,52H17V25.2918l-3.1484-0.9324
C13.5104,24.2504,13.6331,24,13.6331,24H28c0.5522,0,1-0.4478,1-1V8.6341c0,0,0.2509-0.1096,0.3599,0.231L30.2915,12H47V52z`}
				/>
				<rect x="33" y="21" width="10" height="3" />
				<rect x="28" y="31" width="15" height="3" />
				<rect x="21" y="31" width="5" height="3" />
				<rect x="28" y="37" width="15" height="3" />
				<rect x="21" y="37" width="5" height="3" />
				<rect x="21" y="43" width="5" height="3" />
				<rect x="28" y="43" width="15" height="3" />
			</g>
		</g>
	</svg>
);
