import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconGrootstedelijk: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '9 1.16 45 61.84', width: '45', height: '61.84' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M53.5,29H46V19V9V7.3771c0-0.2233-0.148-0.4194-0.3627-0.4808l-20-5.7142C25.3179,1.0909,25,1.3307,25,1.6629V19h6
c0.5523,0,1,0.4477,1,1v24h8h1.4338c0.3513,0,0.6768,0.1843,0.8575,0.4855L48,54v9h6V29.5C54,29.2238,53.7761,29,53.5,29z`}
				/>
				<rect x="39" y="55" width="6" height="5" />
				<path
					d={`M31.544,46.0274L25.3277,54c-0.1558-0.0781-0.2475-0.2174-0.0777-0.5547c2.8723-5.7063,4.75-9.362,4.75-9.362V21.5
c0-0.2762-0.2239-0.5-0.5-0.5h-20C9.2239,21,9,21.2238,9,21.5V63h16h5h6v-9l4.7836-7.9726H31.544z M23,26h3v3h-3V26z M23,31h3v3
h-3V31z M23,36h3v3h-3V36z M16,49h-3v-3h3V49z M16,44h-3v-3h3V44z M16,39h-3v-3h3V39z M16,34h-3v-3h3V34z M16,29h-3v-3h3V29z
M21,49h-3v-3h3V49z M21,44h-3v-3h3V44z M21,39h-3v-3h3V39z M21,34h-3v-3h3V34z M21,29h-3v-3h3V29z M23,44v-3h3v3H23z`}
				/>
			</g>
		</g>
	</svg>
);
