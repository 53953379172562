import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconOndernemen: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '2.79 2.01 56.82 60.22', width: '56.82', height: '60.22' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M25.2407,31.7497c4.0281,0.8611,6.3649-1.44,6.3649-1.44s-1.0931-4.6503-5.2784-5.169
c-2.9612-0.367-5.0839,0.5297-6.0721,1.0856c-0.3745,0.2107-0.524,0.6639-0.3334,1.041
C20.5399,28.4901,22.187,31.0968,25.2407,31.7497z`}
				/>
				<path
					d={`M31.5026,22.9686c1.4315-3.8284,3.4678-6.7917,4.997-8.6081c0.276-0.3278,0.4821-0.2139,0.543-0.1666l-0.002,0.0257
c-3.3562,8.3084-3.55,15.0257-2.4019,20.9587c-3.2192,0.8334-5.6968,3.7969-8.6354,4.181
c-2.4077,0.3146-4.6497,1.111-5.7346,2.8745c0.7786,0.11,1.5374,0.2187,2.2681,0.3242c4.8621,0.7027,8.7023,1.2578,14.705,1.4287
c2.2221,0.0617,3.5159,0.7877,4.2676,1.6481l0.2142-0.0922c4.1205-1.7632,7.4644-3.0975,9.9383-3.9661
c0.2753-0.0968,0.5449-0.1775,0.8132-0.255c-1.4075-1.4045-3.0392-2.4996-4.6483-2.3808c-3.6926,0.2725-6.1689-2.167-8.8759-3.355
c-1.137-3.2815-1.6802-7.3391-1.2902-11.4962c8.2969-0.9985,11.71-13.3702,6.0574-21.686
c-0.3812-0.5608-1.2289-0.5137-1.5605,0.0778c-5.5872,9.9661-11.5369,6.4315-13.3872,13.4912
C28.11,18.4923,29.1333,21.3926,31.5026,22.9686z`}
				/>
				<path
					d={`M52.3908,43.652c-2.9438,1.0334-6.7683,2.6154-9.8017,3.9136c0.0815,0.4607,0.0954,0.9503,0.0223,1.4691
c-0.4222,2.9949-2.9733,4.9034-6.6166,5.0958c-8.2375,0.4349-13.3677-1.5729-14.4325-1.9966
c-0.3058-0.1217-0.3406-0.2723-0.3142-0.4116c0.0015-0.0013,0.0119-0.0098,0.0119-0.0098
c5.9681,0.989,9.7264,1.1326,14.3494,0.3582c3.5074-0.5876,4.7689-2.2753,4.7302-3.6602
c-0.0375-1.3532-1.0015-2.1645-3.1602-2.2255c-7.3437-0.2074-11.6418-0.9984-17.9406-1.8746
c-4.3775-0.6088-8.5873,0.5536-12.1643,2.8782c-3.3568,2.1813-4.2845,3.209-4.2845,3.209l6.2425,11.8336l7.9516-3.2928
c0.9062-0.3753,1.8982-0.4931,2.867-0.3406l10.9496,2.3616c2.4421,0.3845,4.9417-0.0922,7.071-1.3481l21.7336-13.8307
C59.6052,45.7804,58.5072,41.5048,52.3908,43.652z`}
				/>
			</g>
		</g>
	</svg>
);
