import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconNeutraal: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		version="1.2"
		baseProfile="tiny"
		xmlns="http://www.w3.org/2000/svg"
		{...(cropped
			? { viewBox: '3.03 7 57.91 48', width: '57.91', height: '48' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g id="_x36_4px_Box">
			<rect fill="none" width="64" height="64" />
		</g>
		<g id="Icon">
			<rect fill="none" width="64" height="64" />
			<path
				d="M59.9405,35h-1.2866l-5.7941-19.7664c0.335,0.1279,0.627,0.2459,0.856,0.3443c1.1416,0.4878,2.4639-0.0361,2.9561-1.1777
			c0.4902-1.1406-0.0352-2.4634-1.1758-2.9551c-0.7607-0.3286-7.5762-3.1836-12.2354-2.2632
			c-1.6895,0.3325-3.1416,0.8237-4.5449,1.2988c-0.3517,0.119-0.6915,0.2302-1.0322,0.3411
			c-1.0126,2.9198-2.7632,3.5869-3.3889,3.6796c-0.1088,0.0161-0.2028-0.0314-0.2317-0.0803
			c1.1094-0.8579,1.9001-1.7201,1.9352-3.4024C36.0424,8.8997,34.2802,7,32,7c-2.2092,0-4,1.7908-4,4
			c0,0.1154,0.0221,0.2244,0.032,0.3372c-0.9121-0.2398-1.8031-0.5369-2.7478-0.8564c-1.4033-0.4751-2.8555-0.9663-4.5449-1.2988
			c-4.6641-0.9204-11.4746,1.9346-12.2354,2.2632c-1.1416,0.4917-1.668,1.8159-1.1758,2.957s1.8193,1.6675,2.957,1.1748
			c0.2134-0.092,0.4946-0.2056,0.822-0.3304L5.3168,35H4.0303c-0.5838,0-1.0333,0.499-0.9992,1.0818C3.4569,43.3606,8.2051,47,14,47
			s10.5431-3.6394,10.9689-10.9182C25.003,35.499,24.5535,35,23.9697,35h-1.2864l-6.237-21.2769
			c1.2592-0.2229,2.4776-0.3127,3.4238-0.1262c1.3984,0.2754,2.6484,0.6987,3.9717,1.1465c1.5977,0.5403,3.239,1.087,5.1582,1.3896
			V49h-6c-2.2092,0-4,1.7908-4,4v2h26v-2c0-2.2092-1.7908-4-4-4h-6V16.1331c1.9192-0.3027,3.5605-0.8493,5.1582-1.3896
			c1.3232-0.4478,2.5732-0.8711,3.9717-1.1465c0.9465-0.1854,2.1515-0.1007,3.3973,0.1168L41.2875,35H40.001
			c-0.5837,0-1.0333,0.499-0.9991,1.0818C39.4276,43.3606,44.1759,47,49.9708,47c5.7948,0,10.5431-3.6394,10.9689-10.9182
			C60.9738,35.499,60.5242,35,59.9405,35z M14,44c-0.5843,0-1.1321-0.0565-1.6592-0.1438c3.1495-0.5215,5.2603-2.5643,6.0225-5.8562
			h3.3184C20.792,41.8428,18.0762,44,14,44z M19.0354,35H8.9646L14,17.8213L19.0354,35z M49.9707,17.8213L55.006,35H44.9353
			L49.9707,17.8213z M49.9708,44c-0.5843,0-1.1321-0.0565-1.6592-0.1438c3.1495-0.5215,5.2603-2.5643,6.0225-5.8562h3.3184
			C56.7628,41.8428,54.0469,44,49.9708,44z"
			/>
		</g>
	</svg>
);
