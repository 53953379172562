import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconWerkgelegenheid: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '1 6.47 62.81 48.53', width: '62.81', height: '48.53' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M26.8887,36.556c-1.1445-0.3771-2.2466-0.6777-2.9585-0.87c-0.9561-0.2578-1.1162-1.5276-1.1821-2.528
c1.3263-1.5457,2.3101-3.4025,2.6497-5.835C26.7327,26.9071,27,25.2224,27,24.1719c0-1.0502-0.1831-1.6184-0.5562-1.7944
c-0.3262-0.1534-0.6295-0.0384-0.8276,0.0749c0.0142-0.1456,0.0784-0.3035,0.0928-0.4689
c0.7622-0.1945,1.5354-0.3908,2.3391-0.5718c0.2983-0.0672,0.5144-0.3311,0.5144-0.6322v-1.208
c0-0.2757-0.1828-0.5119-0.446-0.6067c-0.4619-0.1664-1.1404-0.5726-1.3176-1.543c-0.2759-1.5102-0.5959-6.3311-6.1114-7.4901
V9.5745c0-0.6892-0.5637-1.2739-1.2514-1.3202c-0.4775-0.0322-2.2695-0.0322-2.7471,0c-0.6877,0.0463-1.2515,0.631-1.2515,1.3202
v0.3572c-5.5154,1.1591-5.8354,5.9799-6.1113,7.4901c-0.1772,0.9703-0.8557,1.3766-1.3176,1.543
c-0.2632,0.0948-0.4461,0.331-0.4461,0.6067v1.208c0,0.3011,0.2161,0.565,0.5144,0.6322c0.7958,0.1792,1.5613,0.3735,2.3162,0.566
c0.0154,0.2048,0.1377,0.4006,0.1528,0.5743c-0.1267-0.1004-0.541-0.3856-0.9897-0.1746C9.1829,22.5535,9,23.1216,9,24.1719
c0,1.0505,0.2672,2.7352,1.6021,3.1512c0.2993,2.5157,1.2668,4.2955,2.5659,5.7808c-0.0449,1.0686-0.1174,2.3165-1.1177,2.596
c-2.0981,0.5864-7.1605,2.1105-8.795,3.8317C1.1689,41.7278,1,50,1,50h16.0784c0.6586-3.5605,1.8154-7.83,3.7552-9.8727
C22.1321,38.7604,24.3876,37.5645,26.8887,36.556z`}
				/>
				<path
					d={`M60.1971,41.976c-1.6394-1.7269-7.0967-3.7097-9.3673-4.4005c-1.1841,4.6586-3.2415,10.5812-6.4467,14.7686
c-0.3698-2.0938-0.9948-4.9089-1.6671-6.8016c0.9163-1.4047,2.1505-3.5873,2.1505-3.5873s-1.051-1.7119-3.442-1.7119
c-2.3911,0-3.442,1.7119-3.442,1.7119s1.2343,2.1826,2.1505,3.5873c-0.6681,1.8807-1.2896,4.6724-1.6603,6.7621
c-3.0587-4.1884-5.1105-10.0984-6.3177-14.7405c-2.387,0.7231-7.5957,2.5936-9.3221,4.4119C20.2065,44.7408,19.185,55,19.185,55
c5.2581,0,38.9442,0,44.625,0C63.81,55,62.8236,44.7408,60.1971,41.976z`}
				/>
				<path
					d={`M32.0684,26.3994c0.5404,4.4968,2.7897,7.128,5.5587,9.4594c1.5987,1.3466,6.1167,1.3424,7.7106,0
c2.5732-2.1664,4.65-4.8598,5.5587-9.4594l0.0202,0.0546c0.0028-0.0187,0.0073-0.0358,0.0099-0.0546
c1.6839-0.519,2.0209-2.6216,2.0209-3.9326c0-1.3105-0.2309-2.0196-0.7014-2.2394c-0.2268-0.1056-0.4485-0.1047-0.6456-0.0628
c0.1693-2.7305,0.3377-8.1407-0.8-10.1736c-1.2443-2.2224-2.8987-2.7988-5.7815-2.0724c-1.8907-1.0031-3.1778-1.9953-6.4902-1.092
c-2.3017,0.6274-4.8207,1.6521-6.1479,3.3617c-1.4322,1.8448-1.1562,7.2255-0.9141,9.9894
c-0.2135-0.0583-0.4637-0.0687-0.7178,0.0497c-0.4705,0.2198-0.7014,0.9289-0.7014,2.2394
C30.0475,23.7778,30.3844,25.8804,32.0684,26.3994z`}
				/>
			</g>
		</g>
	</svg>
);
