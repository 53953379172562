import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconReisdocument: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		version="1.2"
		baseProfile="tiny"
		xmlns="http://www.w3.org/2000/svg"
		{...(cropped
			? { viewBox: '3 10 59 46.64', width: '59', height: '46.64' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g id="_x36_4px_Box">
			<rect fill="none" width="64" height="64" />
		</g>
		<g id="Icon">
			<g>
				<path
					d="M61.933,43.9648l-4.0625-22.0273c-0.2855-1.5479-1.4424-2.7103-2.8705-3.098v2.156
					c0.0273,0.0169,0.0587,0.0233,0.0854,0.0416c0.4326,0.2979,0.7236,0.7471,0.8184,1.2637l4.0625,22.0273
					c0.0947,0.5166-0.0166,1.0391-0.3145,1.4717c-0.2979,0.4336-0.7471,0.7236-1.2637,0.8193l-43.3027,7.9854
					c-1.084,0.1973-2.1299-0.5195-2.3301-1.6045l-2.3602-12.8H8.3613l2.4272,13.1633c0.3555,1.9248,2.041,3.2744,3.9326,3.2744
					c0.2393,0,0.4834-0.0215,0.7275-0.0664l43.3008-7.9854c1.043-0.1914,1.9482-0.7783,2.5488-1.6514
					C61.8998,46.0615,62.1254,45.0078,61.933,43.9648z"
				/>
				<rect
					x="15.0321"
					y="43.4508"
					transform="matrix(0.9834 -0.1813 0.1813 0.9834 -7.6626 5.0952)"
					width="18"
					height="2"
				/>
				<rect
					x="15.8072"
					y="47.9285"
					transform="matrix(-0.9834 0.1814 -0.1814 -0.9834 52.1261 93.0909)"
					width="12"
					height="2"
				/>
				<path
					d="M14,35h1v3h35.0324C51.6714,38,53,36.6713,53,35.0324V12.9676C53,11.3286,51.6714,10,50.0324,10H15v3h-1v-3H6
					c-1.6569,0-3,1.3431-3,3v22c0,1.6569,1.3431,3,3,3h8V35z M23.1478,23.8481c1.7099-1.5092,6.3332-1.6252,6.3332-1.6252
					l6.7629-7.9445c0.1994-0.2437,0.5286-0.339,0.8275-0.2394l0.5076,0.3205c0.3693,0.1536,0.556,0.5676,0.4268,0.9461l-3.2115,7.1833
					l6.0722,0.4931l1.5181-2.0056c0.182-0.2172,0.4698-0.3148,0.7464-0.2534l0.2744,0.061c0.4572,0.1016,0.7141,0.5889,0.5398,1.0236
					L43.1435,24.5l0.8016,2.6925c0.1743,0.4346-0.0826,0.9219-0.5398,1.0236l-0.2744,0.061
					c-0.2766,0.0615-0.5644-0.0363-0.7464-0.2534l-1.5181-2.0056l-6.0722,0.4931l3.2115,7.1833
					c0.1292,0.3785-0.0575,0.7924-0.4268,0.946l-0.5076,0.3205c-0.2988,0.0996-0.6281,0.0043-0.8275-0.2394l-6.7628-7.9445
					c0,0-4.6233-0.116-6.3332-1.6252C22.7617,24.8112,22.7617,24.1888,23.1478,23.8481z M14,15h1v3h-1V15z M14,20h1v3h-1V20z M14,25h1
					v3h-1V25z M14,30h1v3h-1V30z"
				/>
			</g>
		</g>
	</svg>
);
