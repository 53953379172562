import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconBelastingen: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '5.87 11 54.07 47.02', width: '54.07', height: '47.02' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<rect
					x="22.8391"
					y="35.949"
					transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.5085 33.2143)"
					width="16"
					height="6"
				/>
				<path
					d={`M52.3618,25h-5.6752l4.6469,4.6469L27.8051,53.1757L10.717,36.0876L21.8043,25h-5.675l-9.6688,9.6689
c-0.7838,0.7833-0.7838,2.054,0,2.8373l19.9259,19.9259c0.3762,0.3762,0.8867,0.5878,1.4186,0.5878
c0.532,0,1.0425-0.2116,1.4187-0.5878L55.59,31.0655c0.7838-0.7833,0.7838-2.054,0-2.8373L52.3618,25z`}
				/>
				<path
					d={`M59.7665,20.2706l-3.5031-7.7515C55.5969,11.567,54.5079,11,53.3456,11H7.5312C6.6855,11,6,11.6855,6,12.5312V29
c0,0.8062,0.9747,1.2098,1.5447,0.6398L12,25c0,0-3.8199-10.3544-3.8986-10.6251C8.0059,14.0463,8.2879,14,8.2879,14
l4.5129,7.5542C13.3365,22.4509,14.3041,23,15.3487,23h42.6561C59.409,23,60.3448,21.5502,59.7665,20.2706z`}
				/>
			</g>
		</g>
	</svg>
);
