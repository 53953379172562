import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconBudget: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '11.22 9 40.56 46', width: '40.56', height: '46' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<rect fill="none" width="64" height="64" />
			<path
				d={`M47.8774,29.7917c0.9397-2.1949,1.3417-3.8302,1.0032-5.5784c-0.3785-1.9546-3.5978-3.5523-8.3521-4.4434
c-1.4641-0.2744-2.5888-0.1926-3.4426-1.391c-0.3237-0.4542-0.5059-1.027-0.5686-1.681C37.9731,16.0999,39,14.6714,39,13
c0-2.2091-1.7909-4-4-4c-0.7612,0-1.4653,0.2243-2.071,0.5934c0.5556,2.7349-0.1671,3.5018-0.1671,3.5018
c-0.3663,0.4274-0.696,0.205-0.7847,0.131C31.9815,13.1498,32,13.0775,32,13c0-2.2091-1.7909-4-4-4s-4,1.7909-4,4
c0,1.6714,1.0269,3.0999,2.4827,3.6979c-0.0627,0.654-0.2449,1.2268-0.5686,1.681c-0.8539,1.1985-1.9782,1.1165-3.4429,1.3911
c-4.754,0.8912-7.9733,2.4887-8.3518,4.4433c-0.2267,1.171-0.1147,2.2952,0.2621,3.567c3.4362-1.7418,9.7354-2.8303,17.1187-2.8303
c0.09,0.1484-0.0285,0.4528-0.5568,0.5472c-0.4811,0.0859-11.2723,1.2386-16.3098,5.3517
c-2.1046,4.5936-3.458,9.7921-3.4128,13.8141C11.2867,50.5352,15.4073,55,31.4998,55c16.0923,0,20.2135-4.4648,20.2794-10.3371
C51.8279,40.3314,50.2662,34.6257,47.8774,29.7917z M30.0411,19.0306c0.2932-0.8018,0.4879-1.7794,0.4718-2.9418
c0.4031-0.3284,0.7368-0.7298,0.9871-1.1898c0.2503,0.46,0.584,0.8613,0.9871,1.1898c-0.0161,1.1624,0.1786,2.14,0.4718,2.9418
C31.3168,18.9894,31.6829,18.9894,30.0411,19.0306z M36,47.7899c-1.4104,0.3337-2.0767,0.4105-3.4355,0.4105
c-3.5639,0-6.0773-1.7435-6.8204-5.5385H24v-1.9483h1.5388v-0.5384c0-0.4615,0-0.9489,0.0516-1.3846H24v-1.9741h1.8977
c0.9231-3.8459,3.5386-5.7947,7.1025-5.7947c0.9999,0,1.7946,0.0768,2.8713,0.3079v2.6919
c-1.2567-0.2305-1.923-0.3331-2.9482-0.3331c-1.9493,0-3.2313,1.051-3.7954,3.1281h4.6669v1.9741h-4.9996
c-0.0263,0.3589-0.0263,0.7179-0.0263,1.0768c0,0.3079,0,0.59,0.0263,0.8463h4.9996v1.9483h-4.6922
c0.5894,2.1024,1.9745,2.9744,3.666,2.9744c1.2051,0,1.8977-0.1026,3.2313-0.3847V47.7899z`}
			/>
		</g>
	</svg>
);
