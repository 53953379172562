import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconInnovatie: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '2 3 59 57', width: '59', height: '57' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path
					d={`M30.6759,12h1.6482c0.894,0,1.5895-0.7771,1.4908-1.6656L33.164,4.4759C33.0706,3.6357,32.3604,3,31.515,3h-0.03
c-0.8454,0-1.5556,0.6357-1.649,1.4759l-0.6509,5.8585C29.0864,11.2229,29.7819,12,30.6759,12z`}
				/>
				<path
					d={`M14.1896,18.4634c0.5585,0.6981,1.5998,0.7558,2.2319,0.1237l1.1655-1.1655c0.6321-0.6321,0.5745-1.6735-0.1236-2.232
l-4.6028-3.6823c-0.6602-0.5281-1.6118-0.4754-2.2097,0.1224l-0.0212,0.0212c-0.5978,0.5978-0.6505,1.5495-0.1224,2.2097
L14.1896,18.4634z`}
				/>
				<path
					d={`M48.8104,46.5366c-0.5585-0.6981-1.5998-0.7558-2.2319-0.1236l-1.1655,1.1655c-0.6321,0.6321-0.5745,1.6735,0.1236,2.2319
l4.6028,3.6823c0.6602,0.5281,1.6118,0.4754,2.2097-0.1224l0.0212-0.0212c0.5978-0.5978,0.6505-1.5494,0.1224-2.2096
L48.8104,46.5366z`}
				/>
				<path
					d={`M11,33.3241v-1.6482c0-0.894-0.7771-1.5896-1.6656-1.4908L3.4759,30.836C2.6357,30.9294,2,31.6396,2,32.485v0.03
c0,0.8454,0.6357,1.5556,1.4759,1.649l5.8585,0.6509C10.2229,34.9136,11,34.2181,11,33.3241z`}
				/>
				<path
					d={`M59.5241,30.836l-5.8585-0.6509C52.7771,30.0863,52,30.7819,52,31.6759v1.6482c0,0.894,0.7771,1.5895,1.6656,1.4908
l5.8585-0.6509C60.3643,34.0706,61,33.3604,61,32.515v-0.03C61,31.6396,60.3643,30.9294,59.5241,30.836z`}
				/>
				<path
					d={`M16.4216,46.413c-0.6321-0.6322-1.6735-0.5745-2.2319,0.1236l-3.6823,4.6028c-0.5281,0.6602-0.4755,1.6118,0.1224,2.2096
l0.0212,0.0212c0.5978,0.5978,1.5495,0.6505,2.2097,0.1224l4.6028-3.6823c0.6981-0.5585,0.7557-1.5998,0.1236-2.2319
L16.4216,46.413z`}
				/>
				<path
					d={`M46.5784,18.587c0.6321,0.6321,1.6735,0.5745,2.2319-0.1237l3.6823-4.6028c0.5281-0.6602,0.4755-1.6119-0.1224-2.2097
l-0.0212-0.0212c-0.5978-0.5978-1.5495-0.6505-2.2097-0.1224l-4.6028,3.6823c-0.6981,0.5585-0.7557,1.5999-0.1236,2.232
L46.5784,18.587z`}
				/>
				<path
					d={`M47,32.5C47,23.9396,40.0604,17,31.5,17S16,23.9396,16,32.5c0,6.236,3.6909,11.5975,9,14.0571v8.5474
c0,0.8795,0.5726,1.6557,1.4137,1.9127L28,57.5021V59c0,0.5522,0.4477,1,1,1h5c0.5523,0,1-0.4478,1-1v-1.4995l1.582-0.4833
C37.423,56.7603,38,55.984,38,55.1046v-8.5474C43.3091,44.0975,47,38.736,47,32.5z M40.0612,26.6087
C38.4474,25.5934,36.5426,25,34.4951,25c-5.799,0-10.5,4.701-10.5,10.5c0,1.9636,0.5508,3.7927,1.491,5.3629L25.4783,40.86
c0,0-0.0835,0.2077-0.3978,0.0859c-1.037-0.4018-4.3027-3.6539-4.3027-8.446c0-5.9217,4.8005-10.7222,10.7222-10.7222
c4.9298,0,8.2186,3.4644,8.6406,4.4722C40.2474,26.5049,40.1145,26.5865,40.0612,26.6087z`}
				/>
			</g>
		</g>
	</svg>
);
